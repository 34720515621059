import * as _esReflectConstruct2 from "core-js/modules/es.reflect.construct.js";
var _esReflectConstruct = _esReflectConstruct2;
try {
  if ("default" in _esReflectConstruct2) _esReflectConstruct = _esReflectConstruct2.default;
} catch (e) {}
import * as _esSymbolToPrimitive2 from "core-js/modules/es.symbol.to-primitive.js";
var _esSymbolToPrimitive = _esSymbolToPrimitive2;
try {
  if ("default" in _esSymbolToPrimitive2) _esSymbolToPrimitive = _esSymbolToPrimitive2.default;
} catch (e) {}
import * as _esDateToPrimitive2 from "core-js/modules/es.date.to-primitive.js";
var _esDateToPrimitive = _esDateToPrimitive2;
try {
  if ("default" in _esDateToPrimitive2) _esDateToPrimitive = _esDateToPrimitive2.default;
} catch (e) {}
import * as _esSymbol2 from "core-js/modules/es.symbol.js";
var _esSymbol = _esSymbol2;
try {
  if ("default" in _esSymbol2) _esSymbol = _esSymbol2.default;
} catch (e) {}
import * as _esSymbolDescription2 from "core-js/modules/es.symbol.description.js";
var _esSymbolDescription = _esSymbolDescription2;
try {
  if ("default" in _esSymbolDescription2) _esSymbolDescription = _esSymbolDescription2.default;
} catch (e) {}
import * as _esNumberConstructor2 from "core-js/modules/es.number.constructor.js";
var _esNumberConstructor = _esNumberConstructor2;
try {
  if ("default" in _esNumberConstructor2) _esNumberConstructor = _esNumberConstructor2.default;
} catch (e) {}
import * as _esObjectGetOwnPropertyDescriptor2 from "core-js/modules/es.object.get-own-property-descriptor.js";
var _esObjectGetOwnPropertyDescriptor = _esObjectGetOwnPropertyDescriptor2;
try {
  if ("default" in _esObjectGetOwnPropertyDescriptor2) _esObjectGetOwnPropertyDescriptor = _esObjectGetOwnPropertyDescriptor2.default;
} catch (e) {}
import * as _esObjectGetOwnPropertyDescriptors2 from "core-js/modules/es.object.get-own-property-descriptors.js";
var _esObjectGetOwnPropertyDescriptors = _esObjectGetOwnPropertyDescriptors2;
try {
  if ("default" in _esObjectGetOwnPropertyDescriptors2) _esObjectGetOwnPropertyDescriptors = _esObjectGetOwnPropertyDescriptors2.default;
} catch (e) {}
import * as _esSymbolIterator2 from "core-js/modules/es.symbol.iterator.js";
var _esSymbolIterator = _esSymbolIterator2;
try {
  if ("default" in _esSymbolIterator2) _esSymbolIterator = _esSymbolIterator2.default;
} catch (e) {}
import * as _esArrayIterator2 from "core-js/modules/es.array.iterator.js";
var _esArrayIterator = _esArrayIterator2;
try {
  if ("default" in _esArrayIterator2) _esArrayIterator = _esArrayIterator2.default;
} catch (e) {}
import * as _esStringIterator2 from "core-js/modules/es.string.iterator.js";
var _esStringIterator = _esStringIterator2;
try {
  if ("default" in _esStringIterator2) _esStringIterator = _esStringIterator2.default;
} catch (e) {}
import * as _webDomCollectionsIterator2 from "core-js/modules/web.dom-collections.iterator.js";
var _webDomCollectionsIterator = _webDomCollectionsIterator2;
try {
  if ("default" in _webDomCollectionsIterator2) _webDomCollectionsIterator = _webDomCollectionsIterator2.default;
} catch (e) {}
import * as _esRegexpConstructor2 from "core-js/modules/es.regexp.constructor.js";
var _esRegexpConstructor = _esRegexpConstructor2;
try {
  if ("default" in _esRegexpConstructor2) _esRegexpConstructor = _esRegexpConstructor2.default;
} catch (e) {}
import * as _esRegexpExec2 from "core-js/modules/es.regexp.exec.js";
var _esRegexpExec = _esRegexpExec2;
try {
  if ("default" in _esRegexpExec2) _esRegexpExec = _esRegexpExec2.default;
} catch (e) {}
import * as _esRegexpToString2 from "core-js/modules/es.regexp.to-string.js";
var _esRegexpToString = _esRegexpToString2;
try {
  if ("default" in _esRegexpToString2) _esRegexpToString = _esRegexpToString2.default;
} catch (e) {}
import * as _esArraySort2 from "core-js/modules/es.array.sort.js";
var _esArraySort = _esArraySort2;
try {
  if ("default" in _esArraySort2) _esArraySort = _esArraySort2.default;
} catch (e) {}
import * as _esObjectToString2 from "core-js/modules/es.object.to-string.js";
var _esObjectToString = _esObjectToString2;
try {
  if ("default" in _esObjectToString2) _esObjectToString = _esObjectToString2.default;
} catch (e) {}
import * as _webDomCollectionsForEach2 from "core-js/modules/web.dom-collections.for-each.js";
var _webDomCollectionsForEach = _webDomCollectionsForEach2;
try {
  if ("default" in _webDomCollectionsForEach2) _webDomCollectionsForEach = _webDomCollectionsForEach2.default;
} catch (e) {}
import * as _esStringMatch2 from "core-js/modules/es.string.match.js";
var _esStringMatch = _esStringMatch2;
try {
  if ("default" in _esStringMatch2) _esStringMatch = _esStringMatch2.default;
} catch (e) {}
import * as _esArrayFilter2 from "core-js/modules/es.array.filter.js";
var _esArrayFilter = _esArrayFilter2;
try {
  if ("default" in _esArrayFilter2) _esArrayFilter = _esArrayFilter2.default;
} catch (e) {}
import * as _esArrayIndexOf2 from "core-js/modules/es.array.index-of.js";
var _esArrayIndexOf = _esArrayIndexOf2;
try {
  if ("default" in _esArrayIndexOf2) _esArrayIndexOf = _esArrayIndexOf2.default;
} catch (e) {}
import * as _esArrayMap2 from "core-js/modules/es.array.map.js";
var _esArrayMap = _esArrayMap2;
try {
  if ("default" in _esArrayMap2) _esArrayMap = _esArrayMap2.default;
} catch (e) {}
import * as _esArrayConcat2 from "core-js/modules/es.array.concat.js";
var _esArrayConcat = _esArrayConcat2;
try {
  if ("default" in _esArrayConcat2) _esArrayConcat = _esArrayConcat2.default;
} catch (e) {}
import * as _esArraySlice2 from "core-js/modules/es.array.slice.js";
var _esArraySlice = _esArraySlice2;
try {
  if ("default" in _esArraySlice2) _esArraySlice = _esArraySlice2.default;
} catch (e) {}
import * as _esObjectAssign2 from "core-js/modules/es.object.assign.js";
var _esObjectAssign = _esObjectAssign2;
try {
  if ("default" in _esObjectAssign2) _esObjectAssign = _esObjectAssign2.default;
} catch (e) {}
import * as _esObjectKeys2 from "core-js/modules/es.object.keys.js";
var _esObjectKeys = _esObjectKeys2;
try {
  if ("default" in _esObjectKeys2) _esObjectKeys = _esObjectKeys2.default;
} catch (e) {}
import * as _esObjectGetPrototypeOf2 from "core-js/modules/es.object.get-prototype-of.js";
var _esObjectGetPrototypeOf = _esObjectGetPrototypeOf2;
try {
  if ("default" in _esObjectGetPrototypeOf2) _esObjectGetPrototypeOf = _esObjectGetPrototypeOf2.default;
} catch (e) {}
import * as _react2 from "react";
var _react = _react2;
try {
  if ("default" in _react2) _react = _react2.default;
} catch (e) {}
import * as _propTypes2 from "prop-types";
var _propTypes = _propTypes2;
try {
  if ("default" in _propTypes2) _propTypes = _propTypes2.default;
} catch (e) {}
import * as _textareaCaret2 from "textarea-caret";
var _textareaCaret = _textareaCaret2;
try {
  if ("default" in _textareaCaret2) _textareaCaret = _textareaCaret2.default;
} catch (e) {}
import * as _getInputSelection2 from "get-input-selection";
var _getInputSelection = _getInputSelection2;
try {
  if ("default" in _getInputSelection2) _getInputSelection = _getInputSelection2.default;
} catch (e) {}
import * as _lodash2 from "lodash.isequal";
var _lodash = _lodash2;
try {
  if ("default" in _lodash2) _lodash = _lodash2.default;
} catch (e) {}
import * as _scrollIntoViewIfNeeded2 from "scroll-into-view-if-needed";
var _scrollIntoViewIfNeeded = _scrollIntoViewIfNeeded2;
try {
  if ("default" in _scrollIntoViewIfNeeded2) _scrollIntoViewIfNeeded = _scrollIntoViewIfNeeded2.default;
} catch (e) {}
var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;
var exports = {};
!function () {
  "use strict";

  var e = {
      n: function (t) {
        var r = t && t.__esModule ? function () {
          return t.default;
        } : function () {
          return t;
        };
        return e.d(r, {
          a: r
        }), r;
      },
      d: function (t, r) {
        for (var n in r) e.o(r, n) && !e.o(t, n) && Object.defineProperty(t, n, {
          enumerable: !0,
          get: r[n]
        });
      },
      o: function (e, t) {
        return Object.prototype.hasOwnProperty.call(e, t);
      },
      r: function (e) {
        "undefined" != typeof Symbol && Symbol.toStringTag && Object.defineProperty(e, Symbol.toStringTag, {
          value: "Module"
        }), Object.defineProperty(e, "__esModule", {
          value: !0
        });
      }
    },
    t = {};
  e.r(t), e.d(t, {
    default: function () {
      return T;
    }
  }), _esReflectConstruct, _esSymbolToPrimitive, _esDateToPrimitive, _esSymbol, _esSymbolDescription, _esNumberConstructor, _esObjectGetOwnPropertyDescriptor, _esObjectGetOwnPropertyDescriptors, _esSymbolIterator, _esArrayIterator, _esStringIterator, _webDomCollectionsIterator, _esRegexpConstructor, _esRegexpExec, _esRegexpToString, _esArraySort, _esObjectToString, _webDomCollectionsForEach, _esStringMatch, _esArrayFilter, _esArrayIndexOf, _esArrayMap, _esArrayConcat, _esArraySlice, _esObjectAssign, _esObjectKeys, _esObjectGetPrototypeOf;
  var r = _react,
    n = e.n(r),
    o = _propTypes,
    i = e.n(o),
    s = _textareaCaret,
    a = e.n(s),
    u = _getInputSelection,
    l = e.n(u),
    c = _lodash,
    h = e.n(c),
    f = _scrollIntoViewIfNeeded,
    p = e.n(f);
  function g(e) {
    return g = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (e) {
      return typeof e;
    } : function (e) {
      return e && "function" == typeof Symbol && e.constructor === Symbol && e !== Symbol.prototype ? "symbol" : typeof e;
    }, g(e);
  }
  var d = ["Component", "defaultValue", "disabled", "value"];
  function b() {
    return b = Object.assign ? Object.assign.bind() : function (e) {
      for (var t = 1; t < arguments.length; t++) {
        var r = arguments[t];
        for (var n in r) Object.prototype.hasOwnProperty.call(r, n) && (e[n] = r[n]);
      }
      return e;
    }, b.apply(this || _global, arguments);
  }
  function y(e, t) {
    var r = Object.keys(e);
    if (Object.getOwnPropertySymbols) {
      var n = Object.getOwnPropertySymbols(e);
      t && (n = n.filter(function (t) {
        return Object.getOwnPropertyDescriptor(e, t).enumerable;
      })), r.push.apply(r, n);
    }
    return r;
  }
  function m(e) {
    for (var t = 1; t < arguments.length; t++) {
      var r = null != arguments[t] ? arguments[t] : {};
      t % 2 ? y(Object(r), !0).forEach(function (t) {
        v(e, t, r[t]);
      }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(r)) : y(Object(r)).forEach(function (t) {
        Object.defineProperty(e, t, Object.getOwnPropertyDescriptor(r, t));
      });
    }
    return e;
  }
  function v(e, t, r) {
    return (t = O(t)) in e ? Object.defineProperty(e, t, {
      value: r,
      enumerable: !0,
      configurable: !0,
      writable: !0
    }) : e[t] = r, e;
  }
  function j(e, t) {
    for (var r = 0; r < t.length; r++) {
      var n = t[r];
      n.enumerable = n.enumerable || !1, n.configurable = !0, "value" in n && (n.writable = !0), Object.defineProperty(e, O(n.key), n);
    }
  }
  function O(e) {
    var t = function (e, t) {
      if ("object" !== g(e) || null === e) return e;
      var r = e[Symbol.toPrimitive];
      if (void 0 !== r) {
        var n = r.call(e, "string");
        if ("object" !== g(n)) return n;
        throw new TypeError("@@toPrimitive must return a primitive value.");
      }
      return String(e);
    }(e);
    return "symbol" === g(t) ? t : String(t);
  }
  function w(e, t) {
    return w = Object.setPrototypeOf ? Object.setPrototypeOf.bind() : function (e, t) {
      return e.__proto__ = t, e;
    }, w(e, t);
  }
  function S(e, t) {
    if (t && ("object" === g(t) || "function" == typeof t)) return t;
    if (void 0 !== t) throw new TypeError("Derived constructors may only return object or undefined");
    return q(e);
  }
  function q(e) {
    if (void 0 === e) throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    return e;
  }
  function C(e) {
    return C = Object.setPrototypeOf ? Object.getPrototypeOf.bind() : function (e) {
      return e.__proto__ || Object.getPrototypeOf(e);
    }, C(e);
  }
  var P = {
      Component: i().oneOfType([i().string, i().elementType]),
      defaultValue: i().string,
      disabled: i().bool,
      maxOptions: i().number,
      onBlur: i().func,
      onChange: i().func,
      onKeyDown: i().func,
      onRequestOptions: i().func,
      onSelect: i().func,
      changeOnSelect: i().func,
      options: i().oneOfType([i().object, i().arrayOf(i().string)]),
      regex: i().string,
      matchAny: i().bool,
      minChars: i().number,
      requestOnlyIfNoOptions: i().bool,
      spaceRemovers: i().arrayOf(i().string),
      spacer: i().string,
      trigger: i().oneOfType([i().string, i().arrayOf(i().string)]),
      value: i().string,
      offsetX: i().number,
      offsetY: i().number,
      passThroughEnter: i().bool,
      passThroughTab: i().bool,
      triggerMatchWholeWord: i().bool,
      triggerCaseInsensitive: i().bool
    },
    k = function (e) {
      !function (e, t) {
        if ("function" != typeof t && null !== t) throw new TypeError("Super expression must either be null or a function");
        e.prototype = Object.create(t && t.prototype, {
          constructor: {
            value: e,
            writable: !0,
            configurable: !0
          }
        }), Object.defineProperty(e, "prototype", {
          writable: !1
        }), t && w(e, t);
      }(f, e);
      var t,
        o,
        i,
        s,
        c = (i = f, s = function () {
          if ("undefined" == typeof Reflect || !Reflect.construct) return !1;
          if (Reflect.construct.sham) return !1;
          if ("function" == typeof Proxy) return !0;
          try {
            return Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})), !0;
          } catch (e) {
            return !1;
          }
        }(), function () {
          var e,
            t = C(i);
          if (s) {
            var r = C(this || _global).constructor;
            e = Reflect.construct(t, arguments, r);
          } else e = t.apply(this || _global, arguments);
          return S(this || _global, e);
        });
      function f(e) {
        var t;
        return function (e, t) {
          if (!(e instanceof t)) throw new TypeError("Cannot call a class as a function");
        }(this || _global, f), (t = c.call(this || _global, e)).isTrigger = t.isTrigger.bind(q(t)), t.arrayTriggerMatch = t.arrayTriggerMatch.bind(q(t)), t.getMatch = t.getMatch.bind(q(t)), t.handleChange = t.handleChange.bind(q(t)), t.handleKeyDown = t.handleKeyDown.bind(q(t)), t.handleBlur = t.handleBlur.bind(q(t)), t.handleResize = t.handleResize.bind(q(t)), t.handleSelection = t.handleSelection.bind(q(t)), t.updateCaretPosition = t.updateCaretPosition.bind(q(t)), t.updateHelper = t.updateHelper.bind(q(t)), t.resetHelper = t.resetHelper.bind(q(t)), t.renderAutocompleteList = t.renderAutocompleteList.bind(q(t)), t.state = {
          helperVisible: !1,
          left: 0,
          trigger: null,
          matchLength: 0,
          matchStart: 0,
          options: [],
          selection: 0,
          top: 0,
          value: null
        }, t.recentValue = e.defaultValue, t.enableSpaceRemovers = !1, t.refInput = (0, r.createRef)(), t.refCurrent = (0, r.createRef)(), t.refParent = (0, r.createRef)(), t;
      }
      return t = f, (o = [{
        key: "componentDidMount",
        value: function () {
          window.addEventListener("resize", (this || _global).handleResize), window.addEventListener("scroll", (this || _global).handleResize);
        }
      }, {
        key: "componentDidUpdate",
        value: function (e) {
          var t = (this || _global).props.options,
            r = (this || _global).state.caret,
            n = (this || _global).state.helperVisible;
          h()(t, e.options) || this.updateHelper((this || _global).recentValue, r, t), n && (this || _global).refCurrent.current && p()((this || _global).refCurrent.current, {
            boundary: (this || _global).refParent.current,
            scrollMode: "if-needed"
          });
        }
      }, {
        key: "componentWillUnmount",
        value: function () {
          window.removeEventListener("resize", (this || _global).handleResize), window.removeEventListener("scroll", (this || _global).handleResize);
        }
      }, {
        key: "getMatch",
        value: function (e, t, r) {
          var n = this || _global,
            o = (this || _global).props,
            i = o.trigger,
            s = o.matchAny,
            a = o.regex,
            u = new RegExp(a),
            l = i;
          Array.isArray(l) || (l = new Array(i)), l.sort();
          var c = r;
          Array.isArray(r) && l.forEach(function (e) {
            c[e] = r;
          });
          for (var h = this.arrayTriggerMatch(l, u), f = null, p = 0; p < h.length; p++) for (var g = h[p], d = g.triggerStr, b = g.triggerMatch, y = g.triggerLength, v = function () {
              var r = e.substring(j, t).match(u),
                o = -1;
              if (y > 0) {
                var i = b ? j : j - y + 1;
                if (i < 0) return "break";
                if (n.isTrigger(d, e, i) && (o = i + y), !r && o < 0) return "break";
              } else {
                if (r && j > 0) return "continue";
                if (t - (o = 0 === j && r ? 0 : j + 1) == 0) return "break";
              }
              if (o >= 0) {
                var a = c[d];
                if (null == a) return "continue";
                var l = e.substring(o, t),
                  h = a.filter(function (e) {
                    var t = e.toLowerCase().indexOf(l.toLowerCase());
                    return -1 !== t && (s || 0 === t);
                  }),
                  p = d,
                  g = l.length;
                f = null === f ? {
                  trigger: p,
                  matchStart: o,
                  matchLength: g,
                  options: h
                } : m(m({}, f), {}, {
                  trigger: p,
                  matchStart: o,
                  matchLength: g,
                  options: h
                });
              }
            }, j = t - 1; j >= 0; --j) {
            if ("break" === v()) break;
          }
          return f;
        }
      }, {
        key: "arrayTriggerMatch",
        value: function (e, t) {
          return e.map(function (e) {
            return {
              triggerStr: e,
              triggerMatch: e.match(t),
              triggerLength: e.length
            };
          });
        }
      }, {
        key: "isTrigger",
        value: function (e, t, r) {
          if (!e || !e.length) return !0;
          if ((this || _global).props.triggerMatchWholeWord && r > 0 && t.charAt(r - 1).match(/[\w]/)) return !1;
          var n = (this || _global).props.triggerCaseInsensitive;
          return !!(t.substr(r, e.length) === e || n && t.substr(r, e.length).toLowerCase() === e.toLowerCase());
        }
      }, {
        key: "handleBlur",
        value: function (e) {
          var t = (this || _global).props.onBlur;
          this.resetHelper(), t && t(e);
        }
      }, {
        key: "handleChange",
        value: function (e) {
          var t = (this || _global).props,
            r = t.onChange,
            n = t.options,
            o = t.spaceRemovers,
            i = t.spacer,
            s = t.value,
            a = (this || _global).recentValue,
            u = e.target.value,
            c = l()(e.target).end;
          if (u.length || this.setState({
            helperVisible: !1
          }), (this || _global).recentValue = u, this.setState({
            caret: c,
            value: e.target.value
          }), !u.length || !c) return r(e.target.value);
          if ((this || _global).enableSpaceRemovers && o.length && u.length > 2 && i.length) {
            for (var h = 0; h < Math.max(a.length, u.length); ++h) if (a[h] !== u[h]) {
              if (h >= 2 && u[h - 1] === i && -1 === o.indexOf(u[h - 2]) && -1 !== o.indexOf(u[h]) && this.getMatch(u.substring(0, h - 2), c - 3, n)) {
                var f = "".concat(u.slice(0, h - 1)).concat(u.slice(h, h + 1)).concat(u.slice(h - 1, h)).concat(u.slice(h + 1));
                return this.updateCaretPosition(h + 1), (this || _global).refInput.current.value = f, s || this.setState({
                  value: f
                }), r(f);
              }
              break;
            }
            (this || _global).enableSpaceRemovers = !1;
          }
          return this.updateHelper(u, c, n), s || this.setState({
            value: e.target.value
          }), r(e.target.value);
        }
      }, {
        key: "handleKeyDown",
        value: function (e) {
          var t = (this || _global).state,
            r = t.helperVisible,
            n = t.options,
            o = t.selection,
            i = (this || _global).props,
            s = i.onKeyDown,
            a = i.passThroughEnter,
            u = i.maxOptions,
            l = i.passThroughTab,
            c = u > 0 ? Math.min(n.length, u) : n.length;
          if (r) switch (e.keyCode) {
            case 27:
              e.preventDefault(), this.resetHelper();
              break;
            case 38:
              e.preventDefault(), c > 0 && this.setState({
                selection: Math.max(0, c + o - 1) % c
              });
              break;
            case 40:
              e.preventDefault(), c > 0 && this.setState({
                selection: (o + 1) % c
              });
              break;
            case 14:
            case 13:
              a || e.preventDefault(), this.handleSelection(o);
              break;
            case 9:
              l || e.preventDefault(), this.handleSelection(o);
              break;
            default:
              s(e);
          } else s(e);
        }
      }, {
        key: "handleResize",
        value: function () {
          this.setState({
            helperVisible: !1
          });
        }
      }, {
        key: "handleSelection",
        value: function (e) {
          var t = (this || _global).props,
            r = t.spacer,
            n = t.onSelect,
            o = t.changeOnSelect,
            i = (this || _global).state,
            s = i.matchStart,
            a = i.matchLength,
            u = i.options,
            l = i.trigger,
            c = u[e],
            h = (this || _global).recentValue,
            f = 0 === l.length ? "" : h.substring(0, s - l.length),
            p = h.substring(s + a),
            g = {
              target: (this || _global).refInput.current
            },
            d = o(l, c);
          g.target.value = "".concat(f).concat(d).concat(r).concat(p), this.handleChange(g), n(g.target.value), this.resetHelper();
          var b = f.length + d.length + (r ? r.length : 1);
          this.updateCaretPosition(b), (this || _global).enableSpaceRemovers = !0;
        }
      }, {
        key: "updateCaretPosition",
        value: function (e) {
          var t = this || _global;
          this.setState({
            caret: e
          }, function () {
            return (0, u.setCaretPosition)(t.refInput.current, e);
          });
        }
      }, {
        key: "updateHelper",
        value: function (e, t, r) {
          var n = (this || _global).refInput.current,
            o = this.getMatch(e, t, r);
          if (o) {
            var i = a()(n, t),
              s = n.getBoundingClientRect(),
              u = i.top + s.top - n.scrollTop,
              l = Math.min(i.left + s.left - n.scrollLeft, window.innerWidth - 100),
              c = (this || _global).props,
              h = c.minChars,
              f = c.onRequestOptions,
              p = c.requestOnlyIfNoOptions;
            o.matchLength >= h && (o.options.length > 1 || 1 === o.options.length && (o.options[0].length !== o.matchLength || 1 === o.options[0].length)) ? this.setState(m({
              helperVisible: !0,
              top: u,
              left: l
            }, o)) : (p && o.options.length || f(e.substr(o.matchStart, o.matchLength)), this.resetHelper());
          } else this.resetHelper();
        }
      }, {
        key: "resetHelper",
        value: function () {
          this.setState({
            helperVisible: !1,
            selection: 0
          });
        }
      }, {
        key: "renderAutocompleteList",
        value: function () {
          var e = this || _global,
            t = (this || _global).state,
            r = t.helperVisible,
            o = t.left,
            i = t.matchStart,
            s = t.matchLength,
            a = t.options,
            u = t.selection,
            l = t.top,
            c = t.value;
          if (!r) return null;
          var h = (this || _global).props,
            f = h.maxOptions,
            p = h.offsetX,
            g = h.offsetY;
          if (0 === a.length) return null;
          if (u >= a.length) return this.setState({
            selection: 0
          }), null;
          var d = 0 === f ? a.length : f,
            b = a.slice(0, d).map(function (t, r) {
              var o = t.toLowerCase().indexOf(c.substr(i, s).toLowerCase());
              return n().createElement("li", {
                className: r === u ? "active" : null,
                ref: r === u ? e.refCurrent : null,
                key: t,
                onClick: function () {
                  e.handleSelection(r);
                },
                onMouseDown: function (e) {
                  e.preventDefault();
                },
                onMouseEnter: function () {
                  e.setState({
                    selection: r
                  });
                }
              }, t.slice(0, o), n().createElement("strong", null, t.substr(o, s)), t.slice(o + s));
            }),
            y = window.innerWidth - o - p - 5,
            m = window.innerHeight - l - g - 5;
          return n().createElement("ul", {
            className: "react-autocomplete-input",
            style: {
              left: o + p,
              top: l + g,
              maxHeight: m,
              maxWidth: y
            },
            ref: (this || _global).refParent
          }, b);
        }
      }, {
        key: "render",
        value: function () {
          var e = (this || _global).props,
            t = e.Component,
            r = e.defaultValue,
            o = e.disabled,
            i = e.value,
            s = function (e, t) {
              if (null == e) return {};
              var r,
                n,
                o = function (e, t) {
                  if (null == e) return {};
                  var r,
                    n,
                    o = {},
                    i = Object.keys(e);
                  for (n = 0; n < i.length; n++) r = i[n], t.indexOf(r) >= 0 || (o[r] = e[r]);
                  return o;
                }(e, t);
              if (Object.getOwnPropertySymbols) {
                var i = Object.getOwnPropertySymbols(e);
                for (n = 0; n < i.length; n++) r = i[n], t.indexOf(r) >= 0 || Object.prototype.propertyIsEnumerable.call(e, r) && (o[r] = e[r]);
              }
              return o;
            }(e, d),
            a = (this || _global).state.value,
            u = Object.assign({}, s);
          Object.keys(P).forEach(function (e) {
            delete u[e];
          });
          var l = "";
          return null != i ? l = i : a ? l = a : r && (l = r), n().createElement(n().Fragment, null, n().createElement(t, b({
            disabled: o,
            onBlur: (this || _global).handleBlur,
            onChange: (this || _global).handleChange,
            onKeyDown: (this || _global).handleKeyDown,
            ref: (this || _global).refInput,
            value: l
          }, u)), this.renderAutocompleteList());
        }
      }]) && j(t.prototype, o), Object.defineProperty(t, "prototype", {
        writable: !1
      }), f;
    }(n().Component);
  k.propTypes = P, k.defaultProps = {
    Component: "textarea",
    defaultValue: "",
    disabled: !1,
    maxOptions: 6,
    onBlur: function () {},
    onChange: function () {},
    onKeyDown: function () {},
    onRequestOptions: function () {},
    onSelect: function () {},
    changeOnSelect: function (e, t) {
      return e + t;
    },
    options: [],
    regex: "^[A-Za-z0-9\\-_]+$",
    matchAny: !1,
    minChars: 0,
    requestOnlyIfNoOptions: !0,
    spaceRemovers: [",", ".", "!", "?"],
    spacer: " ",
    trigger: "@",
    offsetX: 0,
    offsetY: 0,
    value: null,
    passThroughEnter: !1,
    passThroughTab: !0,
    triggerMatchWholeWord: !1,
    triggerCaseInsensitive: !1
  };
  var T = k;
  exports = t;
}();
export default exports;